<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">遗产地管理责任机构信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <!-- 输入表单注意数据库长度设计 -->
                <el-form-item label="机构名称" prop="jgmc">
                    <el-input
                        v-model="formdata.saveJson.jgmc"
                        :disabled="!isEdit"
                        :maxlength="50"
                        placeholder="请输入机构名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所在行政区">
                    <el-tooltip
                        effect="light"
                        content="从遗产地属性上继承"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.szxzq"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入所在行政区"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="组织机构代码" prop="zzjgdm">
                    <el-input
                        v-model="formdata.saveJson.zzjgdm"
                        :disabled="!isEdit"
                        :maxlength="50"
                        placeholder="请输入组织机构代码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人姓名">
                    <el-tooltip
                        effect="light"
                        content="填写本遗产地相关遗产监测事务联系人的姓名"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.lxrxm"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入联系人姓名"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="联系方式" prop="lxdh">
                    <el-tooltip
                        effect="light"
                        content="填写本遗产地相关遗产监测事务联系人的联系方式"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.lxdh"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入联系方式"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="dzyx">
                    <el-tooltip
                        effect="light"
                        content="填写本遗产地相关遗产监测事务联系人的电子邮箱"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.dzyx"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入电子邮箱"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="上级管理单位">
                    <el-tooltip
                        effect="light"
                        content="填写本遗产地保护管理机构的上级管理单位名称"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.sjgldw"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="上级管理单位"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="保护管理机构级别">
                    <el-select
                        v-model="formdata.saveJson.jgjb"
                        filterable
                        clearable
                        placeholder="请选择保护管理机构级别"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in bjgljgjbList"
                            :key="index"
                            :label="item"
                            :value="item"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="机构人员总数" prop="gzryzs">
                    <el-tooltip
                        effect="light"
                        content="填写在职的正式职工人数"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.gzryzs"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入机构人员总数"
                            @input="changeGzryzs"
                            @change="changeNum('1')"
                        >
                            <template slot="append">人</template>
                        </el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="法定代表人">
                    <el-tooltip
                        effect="light"
                        content="填写保护管理机构法定代表人的姓名"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.fddbr"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入法定代表人"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="行政总负责人">
                    <el-tooltip
                        effect="light"
                        content="填写保护管理机构行政总负责人的姓名"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.xzzfzr"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入行政总负责人"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="安全负责人">
                    <el-tooltip
                        effect="light"
                        content="填写保护管理机构安全负责人的姓名"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.aqfzr"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入安全负责人"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="官方网站" prop="gfwz">
                    <el-tooltip
                        effect="light"
                        content="填写本遗产地保护管理机构的官方网址，若没有，则填无"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.gfwz"
                            :disabled="!isEdit"
                            :maxlength="50"
                            placeholder="请输入官方网站"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="保护管理机构成立时间">
                    <el-date-picker
                        v-model="formdata.saveJson.jgclsj"
                        clearable
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="选择保护管理机构成立时间"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="在编人数" prop="bzrs">
                    <el-input
                        v-model="formdata.saveJson.bzrs"
                        :disabled="!isEdit"
                        :maxlength="50"
                        placeholder="请输入在编人数"
                        @input="changeBzrs"
                        @change="changeNum('2')"
                    >
                        <template slot="append">人</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="主要经费来源">
                    <el-select
                        v-model="formdata.saveJson.zyjfly"
                        filterable
                        clearable
                        placeholder="请选择主要经费来源"
                        :disabled="!isEdit"
                    >
                        <el-option label="财政全额拨款" value="0"></el-option>
                        <el-option label="自收自支" value="1"></el-option>
                        <el-option label="财政差额拨款" value="2"></el-option>
                        <el-option label="其他" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="其他经费来源">
                    <el-input
                        v-model="formdata.saveJson.qt"
                        :disabled="!isEdit"
                        :maxlength="50"
                        placeholder="请输入其他经费来源"
                    ></el-input>
                </el-form-item>
                <el-form-item label="机构撤销时间">
                    <el-date-picker
                        v-model="formdata.saveJson.jgcxsj"
                        clearable
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="选择保护管理机构撤销时间"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="承担的责任" class="from-item-block">
                    <el-tooltip
                        effect="light"
                        content="结合本遗产地的行政区域、遗产要素，填写保护管理机构所承担的责任，填写相应描述性信息，字数限制在5000字以内"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.cddzr"
                            :disabled="!isEdit"
                            placeholder="请输入承担的责任"
                            type="textarea"
                            :autosize="{ minRows: 6 }"
                            :maxlength="500"
                            show-word-limit
                        >
                        </el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="管理区域说明" class="from-item-block">
                    <el-input
                        v-model="formdata.saveJson.glqysm"
                        :disabled="!isEdit"
                        placeholder="请输入管理区域说明"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        :maxlength="500"
                        show-word-limit
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="备注" class="from-item-block">
                    <el-input
                        v-model="formdata.saveJson.bz"
                        :disabled="!isEdit"
                        placeholder="请输入备注"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        :maxlength="500"
                        show-word-limit
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import alertInfo from "@comp/alert_info";
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "pro_manager_institution",
    components: {
        alertInfo,
    },
    mixins: [infoMixin],
    data() {
        let positiveNumber = (rule, value, callback) => {
            let reg = /^(0|\+?[1-9][0-9]*)$/;
            if (value && !reg.test(value)) {
                callback(new Error("请输入0或正整数"));
            } else {
                callback();
            }
        };
        let phoneValid = (rule, value, callback) => {
            let isPhone = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/; // 固话号码带‘-’
            if (value && !isPhone.test(value)) {
                callback(new Error("请输入正确的手机号码或者座机号"));
            } else {
                callback();
            }
        };
        let urlValid = (rule, value, callback) => {
            if (value === "无") {
                callback();
            } else {
                callback();
            }
        };
        return {
            notNeedFile: true,
            // 正厅（局）级、副厅（局）级、正处（县）级、副处（县）级、正科（乡）级、副科（乡）级、股级、副股级、其他
            // 需确定澳门的保护管理机构级别
            bjgljgjbList: [
                "正厅（局）级",
                "副厅（局）级",
                "正处（县）级",
                "副处（县）级",
                "正科（乡）级",
                "副科（乡）级",
                "股级",
                "副股级",
                "其他",
            ],
            snButton: snButton,
            rules: {
                lxdh: [{ validator: phoneValid, trigger: "blur" }],
                dzyx: [
                    {
                        type: "email",
                        message: "请输入正确的电子邮箱",
                        trigger: "blur",
                    },
                ],
                gzryzs: [{ validator: positiveNumber, trigger: "blur" }],
                gfwz: [
                    { validator: urlValid, trigger: "blur" },
                    {
                        type: "url",
                        message: "请输入正确的网址",
                        trigger: "blur",
                    },
                ],
                bzrs: [{ validator: positiveNumber, trigger: "blur" }],
                jgmc: [
                    {
                        required: true,
                        message: "请输入机构名称",
                        trigger: "change",
                    },
                ],
                zzjgdm: [
                    {
                        required: true,
                        message: "请输入组织机构代码",
                        trigger: "blur",
                    },
                ],
            },
            formdata: {
                saveJson: {
                    jgmc: "",
                    szxzq: "",
                    zzjgdm: "",
                    lxrxm: "",
                    lxdh: "",
                    dzyx: "",
                    sjgldw: "",
                    jgjb: "",
                    gzryzs: "",
                    fddbr: "",
                    xzzfzr: "",
                    aqfzr: "",
                    gfwz: "",
                    jgclsj: "",
                    bzrs: "",
                    zyjfly: "",
                    qt: "",
                    cddzr: "",
                    bz: "",
                    jgcxsj: "", // 机构撤销时间
                    glqysm: "", // 管理区域说明
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "17",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        changeGzryzs() {
            this.formdata.saveJson.gzryzs =
                this.formdata.saveJson.gzryzs.replace(/[^0-9]/g, "");
        },
        changeBzrs() {
            this.formdata.saveJson.bzrs = this.formdata.saveJson.bzrs.replace(
                /[^0-9]/g,
                ""
            );
        },
        changeNum(num) {
            if (this.formdata.saveJson.gzryzs && this.formdata.saveJson.bzrs) {
                if (
                    parseInt(this.formdata.saveJson.bzrs, 10) >
                    parseInt(this.formdata.saveJson.gzryzs, 10)
                ) {
                    this.$message({
                        message: "在编人数不能大于机构人员总数",
                        type: "warning",
                    });
                    if (num === "1") {
                        this.formdata.saveJson.gzryzs = "";
                    } else if (num === "2") {
                        this.formdata.saveJson.bzrs = "";
                    }
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.basic_data_edit {
    // border:1px solid red;
}
</style>
